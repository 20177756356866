.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}




@keyframes infiniteScroll {
  from {transform: translateX(0)}
  to {transform: translateX(-50%)}
}
.container {
  width: 100%;
  overflow-x: hidden;
}
.horizontal-scrolling-items {
  display: flex;
  font-size: 40px;
  width: 2600px;
  animation-name: infiniteScroll;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  margin-left:5px;
}
.horizontal-scrolling-items__item {
  white-space: nowrap;
  margin-left:20px;

}


.logo{
  opacity: 0.7;

}
.logo:hover{
  opacity:1;
  cursor: pointer;
}
